.container {
  max-width: 450px;
  margin: auto;
  margin-top: 80px;
}

.head {
  padding: 48px 24px;
  text-align: center;
}

.logoImage {
  width: 200px;
}

.body {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemText {
  font-size: 1em;
  padding: 4px 0;
}

.itemTextTotal {
  font-size: 1.2em;
  padding: 4px 0;
}

.action {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer a {
  font-size: 13px;
  color: gray;
  text-decoration: none;
  padding: 4px;
}

.footer span {
  font-size: 13px;
  color: gray;
  text-decoration: none;
  padding: 4px;
}

.footerRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error {
  padding: 10px;
  text-align: center;
  font-size: 12px;
  color: red;
}

.popUp {
  max-width: 500px;
}

.errorModal {
  max-width: 50%;
  background: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
}
