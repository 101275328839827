.container {
  max-width: 450px;
  margin: auto;
  margin-top: 80px;
}

.head {
  padding: 24px;
}

.titleText {
  font-size: 1.5em;
  line-height: 2em;
  text-align: center;
  font-weight: 300;
}

.logoImage {
  width: 100px;
  padding-left: 12px;
}

.poweredBy {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.85em;
}

.body {
  padding: 24px;
}

.itemText {
  font-size: 1em;
  padding: 4px 0;
}

.itemTextTotal {
  font-size: 1.2em;
  padding: 4px 0;
}

.action {
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer a {
  font-size: 13px;
  color: gray;
  text-decoration: none;
  padding: 4px;
}

.footer span {
  font-size: 13px;
  color: gray;
  text-decoration: none;
  padding: 4px;
}

.footerRight {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceBlock {
  font-size: 40px;
  font-weight: 700;
  padding: 48px 20px;
  text-align: center;
}

.priceContainer {
  display: flex;
  justify-content: center;
}
